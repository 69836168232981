/* @import url('http://fonts.googleapis.com/css?family=Lato:400,700');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap"); */

@font-face {
  font-family: "Adieu-Bold";
  src: local("Adieu-Bold"), url("./assets/fonts/Adieu-Bold.woff") format("woff"),
    url("./assets/fonts/Adieu-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "tensentype-chaoheij";
  src: url("./assets/fonts/TTChaoHeiJ.ttf");
}

@font-face {
  font-family: "NotoSans-Thin";
  src: url("./assets/fonts/NotoSans-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "NotoSans-Regular";
  src: url("./assets/fonts/NotoSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/NotoSans-Regular.ttf");
}

:root {
  /* url: https://colorhunt.co/palette/062c3005595be2d784f5f5f5 */
  --palette-baseline: #f5f5f5;
  --palette-light: #e2d784;
  --palette-medium: #05595b;
  --palette-dark: #062c30;

  /* url: https://colorhunt.co/palette/e5e3c9b4cfb094b49f789395*/
  --palette-alt-baseline: #e5e3c9;
  --palette-alt-light: #b4cfb0;
  --palette-alt-medium: #94b49f;
  --palette-alt-dark: #789395;

  --palette-text-disabled: #5d676d;
  --palette-text-theme: #07b084;
}

body {
  background-color: var(--palette-baseline);
  margin: 0;
  font-family: "NotoSans-Regular";
  color: #333;
}

/* -------- Navbar -------- */
.navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "Adieu-Bold";
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 3em;
  padding-right: 3em;
}

.navbar--mobile {
  display: block;
  justify-content: unset;
  padding-left: 3vw;
  padding-right: 3vw;
}

.navbar .links {
  padding-right: 1rem;
}

.navbar .links a {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin: 0.3rem;
  padding: 0.5rem;
  color: var(--palette-baseline);
  font-weight: 900;
}

.navbar .links a:hover {
  color: var(--palette-light);
  text-decoration: underline;
  text-underline-offset: 8px;
}

.navbar .links .active {
  color: var(--palette-light);
  text-decoration: underline;
  text-underline-offset: 8px;
}

.navbar .links--hide {
  display: none;
}

.navbar .links .toggle__locale {
  text-align: center;
  text-decoration: none;
  margin: 0.3rem;
  padding: 0.5rem;
  color: var(--palette-medium);
  background-color: var(--palette-baseline);
  border: 1px solid var(--palette-dark);
  border-radius: 5px;
  font-weight: 900;
  cursor: pointer;
}

.navbar--mobile .menu__bar--mobile {
  display: flex;
  justify-content: space-between;
}

.navbar .links--mobile a {
  display: block;
  text-align: right;
  text-decoration: none;
  margin: none;
  padding: 2vw;
  font-weight: 900;
  color: var(--palette-baseline);
}

.navbar .links--mobile .active {
  color: var(--palette-light);
}

.navbar--mobile .links--mobile .toggle__locale {
  float: right;
  padding: 1.5vw;
  margin: 4vw;
  text-align: center;
  color: var(--palette-medium);
}

/* -------- HOME -------- */

.home .intro {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.home .intro__background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 1;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.home .intro__background:nth-child(1) {
  -webkit-animation: kenburns1 20s linear infinite;
  animation: kenburns1 20s linear infinite;
  z-index: -1;
}

.home .intro__background:nth-child(2) {
  -webkit-animation: kenburns2 20s linear infinite;
  animation: kenburns2 20s linear infinite;
  z-index: -2;
}

.home .intro__background:nth-child(3) {
  -webkit-animation: kenburns3 20s linear infinite;
  animation: kenburns3 20s linear infinite;
  z-index: -3;
}

.intro__text {
  padding-top: 35vh;
  padding-left: 10vw;
  max-width: 40vw;
  color: var(--palette-baseline);
}

.intro__text--mobile {
  max-width: 80vw;
}

.intro__primary {
  font-size: 48px;
}

.intro__primary--mobile {
  font-size: 7.5vw;
}

.intro__secondary {
  padding-bottom: 3rem;
  font-size: 24px;
  color: var(--palette-baseline);
}

.intro__secondary--mobile {
  padding-bottom: 3.5vh;
  font-size: 5.8vw;
}

.intro__portfolio {
  padding: 1rem;
  text-decoration: none;
  font-size: 18px;
  font-weight: 300;
  color: var(--palette-medium);
  border: 1px solid var(--palette-baseline);
  background-color: var(--palette-baseline);
  border-radius: 0.1em;
  transition: box-shadow 300ms;
}

.intro__portfolio--mobile {
  font-size: 3.7vw;
}

.intro__portfolio:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.aboutUs {
  display: flex;
  justify-content: center;
}

.aboutUs--desktop {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.aboutUs--mobile {
  padding-top: 8vh;
  padding-bottom: 8vh;
}

.aboutUs .title {
  font-size: 48px;
  padding-bottom: 1.5rem;
  text-align: center;
  color: var(--palette-medium);
}

.aboutUs .title--mobile {
  font-size: 7.5vw;
}

.aboutUs .content {
  text-align: center;
  max-width: 880px;
  font-family: "NotoSans-Thin";
  font-weight: 200;
  font-size: 24px;
}

.aboutUs .content--mobile {
  font-size: 4.5vw;
}

/* -------- Home Mission --------- */

.mission {
  padding-top: 2rem;
  /* background-color: var(--palette-medium); */
  display: flex;
  justify-content: space-between;
  position: relative;
}

.mission__carousel {
  margin-left: 10%;
  height: 100%;
  width: 33%;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.mission__carousel--mobile {
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.mission__card {
  padding-left: 3rem;
  color: var(--palette-text-disabled);
  border-left: 3px solid var(--palette-text-disabled);
  position: relative;
  transition-duration: 1s;
  z-index: 100;
}

.mission__card img {
  filter: grayscale(0.5) brightness(0.5);
  transition-duration: 1s;
}

.mission__card:hover {
  /* padding-left: 35px; */
  border-left: 3px solid white;
  color: white;
  position: relative;
  /* left: -1px; */
}

.mission__card--active {
  border-left: 3px solid white;
  color: white;
  position: relative;
  filter: none;
}

.mission__card--active img {
  filter: none;
}

.mission__card .title {
  font-size: x-large;
  padding-bottom: 1rem;
}

.mission__card .content {
  font-size: large;
  line-height: 130%;
  padding-bottom: 1.5rem;
}

.mission_circles {
  position: relative;
}

.circle-wrapper {
  position: absolute;
  top: 55%;
  right: 0;
  width: 800px;
  height: 800px;
  transform: translateY(-50%);
}

.circle__link {
  width: 800px;
  height: 800px;
}

.circle {
  transition-duration: 1s;
  object-fit: cover;
}

.circle:hover {
  filter: unset;
}

.circle1 {
  position: absolute;
  width: 180px;
  height: 180px;
  top: 22px;
  left: 465px;
  border-radius: 50%;
  filter: grayscale() opacity(25%);
}

.circle2 {
  position: absolute;
  width: 310px;
  height: 310px;
  top: 134px;
  left: 49px;
  border-radius: 50%;
  filter: grayscale() opacity(25%);
}

.circle3 {
  position: absolute;
  width: 130px;
  height: 130px;
  top: 292px;
  left: 604px;
  border-radius: 50%;
  filter: grayscale() opacity(25%);
  /* mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0)); */
}

.circle4 {
  position: absolute;
  width: 245px;
  height: 245px;
  top: 500px;
  left: 264px;
  border-radius: 50%;
  filter: grayscale() opacity(25%);
}

.circle-wrapper .circle--active {
  filter: unset;
  mask-image: none;
}

/* -------- Home Portfolio Carousel -------- */

.selected-portfolio {
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: var(--palette-medium);
}

.selected-portfolio .title {
  padding-bottom: 1.5rem;
  font-size: 30px;
}

.selected-portfolio .title--mobile {
  font-size: 7vw;
}

.portfolio-carousel .react-multi-carousel-item {
  margin-bottom: 1rem;
}

.selected-portfolio .carousel__link {
  display: flex;
  justify-content: right;
  font-weight: 100;
  color: var(--palette-medium);
}

.portfolio-carousel .card {
  background: linear-gradient(
    197.2deg,
    #9ad2d1 11.71%,
    #51aaae 55.67%,
    #777aa5 90.03%
  );
}

/* keep the space when no website assigned */
/* .portfolio-carousel .card__website:empty::before{
  content: "";
  display: inline-block;
} */

.portfolio-carousel .card__title {
  font-family: "Adieu-Bold";
  font-size: 22px;
  color: var(--palette-baseline);
}

.portfolio-carousel .card__secondary {
  font-family: "tensentype-chaoheij";
  font-size: 22px;
  color: var(--palette-baseline);
}

.portfolio-carousel .card__secondary:empty::before {
  content: "";
  display: inline-block;
}

.portfolio-carousel .card__content {
  color: var(--palette-baseline);
  font-family: "NotoSans-Regular";
  letter-spacing: 0.1em;
  font-size: 14px;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spacing--normal {
  letter-spacing: normal;
}

.portfolio-carousel .card__website {
  color: var(--palette-baseline);
  font-family: "NotoSans-Regular";
  text-align: left;
  font-size: 14px;
  text-decoration: none;
}

.portfolio-carousel .card__website:hover {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
}

/* -------- Portfolio -------- */
.portfolio {
  padding-top: 8em;
}

.portfolio__intro {
  display: flex;
  justify-content: center;
}

.portfolio .intro__summary {
  width: 70%;
}

.portfolio__intro .title {
  font-size: 48px;
  color: var(--palette-medium);
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 3rem;
}

.portfolio__intro .title--mobile {
  font-size: 7.5vw;
}

.portfolio__intro .content {
  font-family: "NotoSans-Thin";
  font-weight: 200;
  font-size: 24px;
  text-align: center;
}

.portfolio__intro .content--mobile {
  font-size: 4.5vw;
}

.portfolio__board {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding-top: 3rem;
}

.invest__card {
  width: 25%;
  position: relative;
  border: none;
  background-color: transparent;
  transition: background-color 300ms ease-in;
}

.invest__card--mobile {
  width: 50%;
}

.invest__card:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.invest__card .logo__wrapper {
  position: absolute;
  left: 20%;
  right: 20%;
  top: 20%;
  bottom: 20%;
  width: auto;
}

.invest__card img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  filter: brightness(0);
}

.invest__card:hover {
  background-color: var(--palette-medium);
  cursor: pointer;
}

.invest__card:hover img {
  filter: brightness(0) invert(1);
}

.invest__modal {
  background-color: rgba(6, 30, 41, 0.9);
}

.invest__modal .modal__content {
  position: absolute;
  top: 8%;
  left: 8%;
  width: 60%;
  color: var(--palette-baseline);
}

.invest__modal .modal__content--mobile {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal__content .close--mobile {
  padding-top: 3vh;
  padding-left: 8vw;
}

.modal__content .wrapper {
  padding-left: 64px;
  padding-top: 64px;
}

.modal__content .wrapper--mobile {
  padding-left: 8vw;
  padding-right: 8vw;
  padding-top: 3vh;
}

.modal__content .area {
  color: var(--palette-text-theme);
  font-size: 20px;
}

.modal__content .area--mobile {
  padding-top: 6vh;
}

.modal__content .title {
  font-size: 46px;
  font-family: "Adieu-Bold";
}

.modal__content .title--mobile {
  font-size: 7.5vw;
}

.modal__content .chn {
  padding-top: 40px;
  font-size: 30px;
  font-family: "tensentype-chaoheij";
  color: var(--palette-text-theme);
}

.modal__content .chn--mobile {
  padding-top: 4vh;
  font-size: 7.5vw;
}

.modal__content .description {
  padding-top: 40px;
  font-size: 20px;
  width: 0.7;
}

.modal__content .description--mobile {
  padding-top: 4vh;
  font-size: 5vw;
}

.modal__content .url {
  padding-top: 40px;
  font-size: 20px;
  color: var(--palette-text-theme);
}

.modal__content .url--mobile {
  padding-top: 4vh;
  font-size: 5vw;
}

/* -------- News -------- */

.news {
  padding-top: 8em;
}

.news__intro {
  display: flex;
  justify-content: center;
  padding-top: 5rem;
}

.news__intro .title {
  font-size: 48px;
  color: var(--palette-medium);
  text-align: center;
}

.news__intro .title--mobile {
  font-size: 7.5vw;
}

.news__board {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
}

.news__cards {
  padding-right: 2rem;
  flex: 3;
}

.news__index {
  width: auto;
  padding-left: 2rem;
  border-left: 2px solid black;
  flex: 1;
}

.news__index .update__title {
  font-size: 20px;
  padding-bottom: 2rem;
}

.news__index .updates {
  padding-bottom: 2rem;
  font-family: "NotoSans-Regular";
  transition: text-shadow 200ms;
}

.news__index .updates:hover {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.news__index .updates .title {
  font-size: 14px;
  color: var(--palette-medium);
}

.news__index .updates .date {
  font-size: 12px;
  color: var(--palette-text-disabled);
}

.news__cards .article__card {
  padding-bottom: 2rem;
}

.news__cards .card__link {
  display: flex;
  justify-content: left;
}

.article__card .img__area {
  flex: 1;
  padding-right: 2rem;
  height: 100%;
}

.article__card .img__area img {
  object-fit: cover;
  transition: box-shadow 500ms;
  border-radius: 5px;
}

.article__card .img__area img:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card__textarea {
  flex: 3;
}

.card__textarea .card__title {
  font-family: "tensentype-chaoheij";
  font-size: 30px;
  font-weight: 500;
  transition: text-shadow 200ms;
  text-decoration: none;
  color: var(--palette-medium);
}

.card__textarea .card__title:hover {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.card__textarea .card__title--mobile {
  font-size: 5vw;
}

.card__textarea .card__headline {
  font-size: 16px;
  font-family: "NotoSans-Regular";
  color: var(--palette-text-disabled);
  padding-top: 0.6rem;
}

.card__textarea .card__headline--mobile {
  font-size: 3vw;
}

/* -------- Footer -------- */

.footer {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2em;
  padding-bottom: 2em;
  color: var(--palette-baseline);
  background-color: var(--palette-medium);
  display: flex;
  justify-content: space-between;
}

.footer--mobile {
  display: inline-block;
}

.footer .footer__logo {
  /* padding-left: 10rem; */
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer .footer__links {
  display: flex;
}

.footer .footer__links tr {
  height: 2rem;
}

.footer .footer__links .links a {
  text-decoration: none;
  font-weight: 900;
  color: var(--palette-baseline);
}

.footer .social-media {
  color: #333;
  padding-left: 4rem;
}

.footer .social-media tr {
  cursor: pointer;
}

.footer .social-media svg {
  filter: invert() sepia(0.5);
}

.footer .social-media svg:hover {
  filter: invert() sepia(0.2);
}

/* ## icon hopping effect
.footer svg {
  transition: top ease 0.5s;
  position: relative;
}

.footer svg:hover {
  top: -0.3em;
} */

.footer .footer__content {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.footer .copyright {
  padding-top: 1rem;
}

.wechat-popup__wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.wechat-popup__wrapper .wechat2d__popup {
  visibility: hidden;
  width: 100px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.wechat-popup__wrapper .wechat2d__popup::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.wechat-popup__wrapper .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.wechat2d__popup .wechat2d__img {
  width: 90px;
}

/* Popup animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.locale--sc {
  font-family: "tensentype-chaoheij";
}

.locale--en {
  font-family: "Adieu-Bold";
}

/* -------- Admin -------- */

.admin, .login {
  padding-top: 8em;
  padding-left: 5em;
  padding-right: 5em;
  padding-bottom: 5em;
}

.login--window {
  max-width: 400px;
  margin: 0 auto;
  text-align: left;
}

.admin--table {
  border: 1px solid black;
  border-collapse: collapse;
  border-spacing: 0;
}

.admin--table th {
  border: 1px solid black;
}

.admin--table td {
  border: 1px solid black;
  padding: 1rem;
}

.admin--add__button {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.article--mod {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.article--mod h2 {
  font-size: 20px;
  color: var(--palette-dark);
  margin-bottom: 30px;
}

.article--mod label {
  text-align: left;
  display: block;
}

.article--mod input,
.article--mod textarea {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}

.alert--success {
  padding: 1rem;
  color: white;
  background-color: green;
  border-radius: 0.3rem;
}

.alert--error {
  padding: 1rem;
  background-color: red;
  color: white;
  border-radius: 0.3rem;
}

/* -------- intro background webkit animations -------- */

@-webkit-keyframes kenburns1 {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  30% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  33.333% {
    opacity: 0;
  }

  97% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes kenburns2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  30% {
    opacity: 0;
  }

  33.333% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  63% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  66.666% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes kenburns3 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  63% {
    opacity: 0;
  }

  66.666% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  97% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* -------- intro background animations -------- */

@keyframes kenburns1 {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  30% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  33.333% {
    opacity: 0;
  }

  97% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
  }
}

@keyframes kenburns2 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  30% {
    opacity: 0;
  }

  33.333% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  63% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  66.666% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes kenburns3 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  63% {
    opacity: 0;
  }

  66.666% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  97% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
